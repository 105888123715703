
import BaseModal from "./BaseModal";

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseModal,
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale;
    },
    supportedLanguages() {
      return this.$i18n.locales.map((locale) => locale.code);
    },
    languages() {
      return this.supportedLanguages.map((lang) => {
        return {
          code: lang,
          name: `global.languages.${lang}`,
          flag: require(`@/assets/static/images/countries/${lang}.svg`),
        };
      });
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    selectLanguage(lang) {
      console.log("Setting language to => ", lang);
      this.closeModal();
      this.$i18n.setLocale(lang);
      window.localStorage.setItem("locale", lang);

      this.$axios.put("/users/me/language").catch(() => {});

      this.$nextTick(() => window.scrollTo(0, 0));
    },
  },
};
