
export const state = () => ({
});

export const getters = {}

export const mutations = {
  SET_SITES(state, payload) {
    state.sites = payload;
  },
};

export const actions = {
  async getDetails({ commit }, id) {
    try {
      commit('SET_LOADING', true, { root: true });
      const res = await this.$axios.get(`/organizations/${id}`);
      return res.data;

    } catch (err) {
      console.error(err);
      return null;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },
};
