
export default {
  props: {
    color: {
      type: String,
    },
    top: {
      type: Boolean,
      default: true
    },
    bottom: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    openOnClick: {
      type: Boolean,
      default: false
    },
    removeOpacity: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: String
    },
    contentClass: {
      type: String
    },
    activatorClass: {
      type: String
    },
  },
  computed: {
    getContentClass() {
      const defaults = "dink-tooltip";
      const dynamic = [];
      let retStr = "";

      if (this.removeOpacity) dynamic.push("remove-opacity");
      if (this.contentClass !== null) dynamic.push(this.contentClass);

      if (!dynamic.length) {
        return defaults;
      } else {
        dynamic.forEach((c) => {
          retStr += c + " ";
        });
      }
      return defaults + ' ' + retStr;
    },
  },
};
