export const state = () => ({
    attributes: [],
    attributeValue: {}
});

export const getters = {
}

export const mutations = {
    SET_ATTRIBUTES(state, payload) {
        state.attributes = payload;
    },
    ADD_ATTRIBUTE_VALUE(state, data) {
        let attr = state.attributes.find(att => att.code === data.attributeCode);
        attr = data.payload;
    },
    SET_ATTRIBUTE_VALUE(state, data) {
        state.attributeValue = data
    },
    DELETE_ATTRIBUTE_VALUE(state, data) {
        let attr = state.attributes.find(att => att.code === data.attributeCode);
        const index = attr.values.findIndex(v => v.id === data.attributeValueID);
        attr.values.splice(index, 1);
    }

};

export const actions = {
    async createAttributeValue({ commit, rootState }, data) {
        commit('SET_LOADING', true, { root: true });
        const code = data.code;
        const payload = data.payload;

        try {
            const res = await this.$axios.post(`/attributes/${code}/attributeValues`, payload, {
                headers: {
                    Authorization: "Bearer " + rootState.localStorage.bearer,
                }
            });
            commit('ADD_ATTRIBUTE_VALUE', { attributeCode: code, payload: res.data });
            commit("user/SHOW_MESSAGE", {
                color: "success",
                message: "Abbiamo aggiornato i dati con successo",
                show: true,
                timeout: 3000
            }, { root: true });
            return res.data.id;
        } catch (err) {
            console.warn("Something went wrong while fetching this promoter's attribute value", err),
                commit("user/SHOW_MESSAGE", {
                    color: "error",
                    message: "Ops, qualcosa è andato storto nel creare questo campo",
                    show: true,
                    timeout: 3000
                }, { root: true });
        }

        commit('SET_LOADING', false, { root: true });
        return false;
    },
    async deleteAttributeValue({ commit, rootState }, data) {
        commit('SET_LOADING', true, { root: true });
        const code = data.code;
        const id = data.attributeValueID;

        try {
            const res = await this.$axios.delete(`/attributes/${code}/attributeValues/${id}`, {
                headers: {
                    Authorization: "Bearer " + rootState.localStorage.bearer,
                }
            });
            commit('DELETE_ATTRIBUTE_VALUE', { attributeCode: code, attributeValueID: id });
            commit("user/SHOW_MESSAGE", {
                color: "success",
                message: "Abbiamo aggiornato i dati con successo",
                show: true,
                timeout: 3000
            }, { root: true });
            return res.data.id;
        } catch (err) {
            console.warn("Something went wrong while deleting this promoter's attribute value", err),
                commit("user/SHOW_MESSAGE", {
                    color: "error",
                    message: "Ops, qualcosa è andato storto nel cancellare questo dato",
                    show: true,
                    timeout: 3000
                }, { root: true });
        }

        commit('SET_LOADING', false, { root: true });
        return false;
    },
    async getMyAttributes({ commit, rootState }, filter) {
        let qs = ''
        if (filter) {
            qs = `?${filter.property}=${filter.value}`
        }
        commit('SET_LOADING', true, { root: true });

        try {
            const res = await this.$axios.get(`/attributes${qs}`, {
                headers: {
                    Authorization: "Bearer " + rootState.localStorage.bearer,
                }
            });
            commit('SET_ATTRIBUTES', res.data);
        } catch (err) {
            console.warn("Something went wrong while fetching this promoter's attributes", err),
                commit("user/SHOW_MESSAGE", {
                    color: "error",
                    message: "Ops, qualcosa è andato storto nel reperire i tuoi livelli",
                    show: true,
                    timeout: 3000
                }, { root: true });
        }

        commit('SET_LOADING', false, { root: true });
    },
    async getAttributeValueById({ commit, rootState }, data) {

        const code = data.code;
        const id = data.attributeValueID;

        commit('SET_LOADING', true, { root: true });

        try {
            const res = await this.$axios.get(`/attributes/${code}/attributeValues/${id}`, {
                headers: {
                    Authorization: "Bearer " + rootState.localStorage.bearer,
                }
            });
            commit('SET_ATTRIBUTE_VALUE', res.data);
        } catch (err) {
            console.warn("Something went wrong while fetching this promoter's attribute value", err),
                commit("user/SHOW_MESSAGE", {
                    color: "error",
                    message: "Ops, qualcosa è andato storto nel reperire questi dati",
                    show: true,
                    timeout: 3000
                }, { root: true });
        }

        commit('SET_LOADING', false, { root: true });
    },
    async patchAttributeValue({ commit, rootState }, data) {

        const code = data.code;
        const attributeValue = data.attributeValue;

        commit('SET_LOADING', true, { root: true });

        try {
            const res = await this.$axios.patch(`/attributes/${code}/attributeValues/${attributeValue.id}`, attributeValue, {
                headers: {
                    Authorization: "Bearer " + rootState.localStorage.bearer,
                }
            });
            commit('SET_ATTRIBUTE_VALUE', res.data);
            commit("user/SHOW_MESSAGE", {
                color: "success",
                message: "Abbiamo aggiornato i tuoi dati con successo",
                show: true,
                timeout: 3000
            }, { root: true });
        } catch (err) {
            console.warn("Something went wrong while patching this promoter's attribute value", err),
                commit("user/SHOW_MESSAGE", {
                    color: "error",
                    message: "Ops, qualcosa è andato storto nel reperire questi dati",
                    show: true,
                    timeout: 3000
                }, { root: true });
        }

        commit('SET_LOADING', false, { root: true });
    },

    /**
     * Reorder the levels by rank
     * @param {Array<{id: string, ranking: number}>} rankings
     */
    async setPosition({commit, dispatch}, {code, items}) {
        try {
          commit('SET_LOADING', true, { root: true });
          await this.$axios.post(`/attributes/${code}/attributeValues/reorder`, {
            positions: items
          });
          await dispatch('getMyAttributes');

        } catch (error) {
          console.warn("Something went wrong while reordering the " + code, err);
        } finally {
          commit('SET_LOADING', false, { root: true });
        }
    }

};
