
export default {
  props: {
    drawer: {
      type: Boolean,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    drawerInput(e) {
      this.$emit('drawerInput', e)
    },
    drawerTransitioned(e) {
      this.$emit('drawerTransition', e)
    },
    drawerUpdated(e) {
      this.$emit('drawerUpdate', e)
    },
    clickOutside() {
      alert('click outside')
    }
  }
};
