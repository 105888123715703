const units = {
  B: 1,
  KB: Math.pow(1024, 1),
  MB: Math.pow(1024, 2),
  GB: Math.pow(1024, 3),
  TB: Math.pow(1024, 4),
};

/**
 * Convert from a human readable size to machine readable
 * @param {string | number} size 
 * @returns {number}
 */
export default function byte(size) {
  if (typeof size === 'number') return size;

  const regex = /^(\d+(\.\d+)?)\s*(B|KB|MB|GB|TB)$/i;
  const matches = size.match(regex);

  if (matches) {
    const value = parseFloat(matches[1]);
    const unit = matches[3].toUpperCase();
    return value * units[unit];
  }

  throw new Error('Invalid size format. Please use a format like "1MB".');
}
