export default function({ store, route, redirect }) {
  const { bearer, loggedIn, staffMember } = store.state.localStorage;

  if ((!staffMember || !bearer) && !route.name?.includes('Login')) {
    if (!route.name.includes('RecoverPassword') && !route.name.includes('ResetPassword')) {
      console.warn("Navigation blocked from Auth middleware")
      return redirect('/login');
    }
  }
  else if (loggedIn && staffMember && bearer && route.path === '/login' && !route.query.changeContext) {
    return redirect('/');
  }
}
