export const state = () => ({
  pwdRecover: {
    mailAddress: '',
    mailSent: false,
  },
  snackbar: {
    show: false,
    message: "",
    timeout: 2000,
  },
  password: {
    lastUpdate: null,
  },
});

export const mutations = {
  USER_RESET_PASSWORD(state, payload) {
    state.pwdRecover.mailAddress = payload;
    state.pwdRecover.mailSent = true;
  },
  RESET_PASSWORD_RECOVER(state) {
    state.pwdRecover = {
      mailAddress: '',
      mailSent: false,
    }
  },
  PASSWORD_UPDATED(state, payload) {
    state.password.lastUpdate = payload
  },
  SHOW_MESSAGE(state, payload) {
    state.snackbar = {
      show: true,
      timeout: 3000,
      ...payload,
    };
  },
  HIDE_SNACKBAR(state, payload) {
    state.snackbar.show = payload;
  }
};

export const actions = {
  async logIn({ commit }, auth) {
    try {
      const { data } = await this.$axios.post('/users/login', undefined, { auth });

      commit("localStorage/GOT_USER_BEARER", data?.jwt, { root: true });
      return data?.jwt;
    } catch (error) {
      const errorName = error?.response?.data?.name;
      if (errorName === 'notFound' || errorName === 'unauthorized') {
        commit("SHOW_MESSAGE", {
          show: true,
          message: this.$i18n.t('errors.auth.wrongEmailOrPasswordError'),
          timeout: 2000,
          color: "error"
        });
      }
      return null;
    }
  },

  async logInWithStaffMember({ commit, rootState }, { id }) {
    try {
      const { data } = await this.$axios.post('/staffMembers/login', { id }, {
        headers: {
          Authorization: "Bearer " + rootState.localStorage.userBearer,
        }
      });

      commit("localStorage/GOT_BEARER", data?.jwt, { root: true });
      return data?.jwt;
    } catch (error) {
      console.error(error)
      return null;
    }
  },
  async fetchMe({ commit }) {
    try {
      const result = await this.$axios.get('/users/me');
      commit('localStorage/SET_ME', result.data, { root: true });
    } catch (err) {
      commit("SHOW_MESSAGE", {
        show: true,
        message: "Problemi nel reperire i dati dell'utente",
        timeout: 2000,
      });
      commit('localStorage/USER_LOGGED_OUT', null, { root: true });
      console.warn("There was an error fetching me", err)
    }
  },

  async logOut({ commit, rootState }) {
    try {
      await this.$axios.post('/users/logout', null, {
      });
      return true;
    } catch (err) {
      console.log("There was an errror during LOGOUT", err);
      const snackbar = {
        color: "error",
        message: "Qualcosa è andato storto. Riporta l'informazione a un amministratore",
        show: true,
        timeout: 3000
      };
      commit("SHOW_MESSAGE", snackbar);
      return false;
    } finally {
      commit('localStorage/USER_LOGGED_OUT', null, { root: true });
      commit("localStorage/GOT_BEARER", null, { root: true });
      commit("localStorage/GOT_USER_BEARER", null, { root: true });
    }
  },

  async recoverUserPassword({ commit }, email) {
    commit('SET_LOADING', true, { root: true });

    try {
      await this.$axios.post('/users/recovery-token', { email });

      commit("USER_RESET_PASSWORD", email)
      commit("SHOW_MESSAGE", {
        color: "success",
        show: true,
        message: "Ti abbiamo inviato una mail contenente il codice per recuperare la password",
        timeout: 2000,
      });
    } catch (error) {
      let errorMessage = "";
      switch (error.response.status) {
        case 500:
          errorMessage = "Problema sconosciuto del server, contatta un amministratore"
          break;
        case 404:
          errorMessage = "Questo indirizzo email non è presente nel nostro sistema, prova di nuovo!"
      }

      commit("SHOW_MESSAGE", {
        color: "error",
        message: errorMessage,
        show: true,
        timeout: 4000
      });
      console.warn("There was an errror during PASSWORD RECOVER", error);
    }
    commit('SET_LOADING', false, { root: true });
  },
  async resetUserPassword ( { commit }, payload) {
    try {
      const res = await this.$axios.post('/users/set-password', payload);
      if (res) {
        commit("SHOW_MESSAGE", {
          color: "success",
          message: "Abbiamo salvato la tua nuova password con successo",
          show: true,
          timeout: 3000,
        });
        return true;
      }
    } catch (error) {
      console.error('Something went wrong while changing password');
      commit("SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto nel modificare la tua password",
        show: true,
        timeout: 3000,
      });
      return false;
    }
  },

  async patchMe({ commit, state, rootState }, data) {
    commit('SET_LOADING', true, { root: true });
    try {
      let userId = rootState.localStorage.me.id;

      const res = await this.$axios.patch("/promoters/" + userId, data);
      commit('localStorage/USER_UPDATED', res.data, { root: true });
      let snackbar = {
        color: "success",
        message: "Abbiamo salvato le tue nuove informazioni con successo",
        show: true,
        timeout: 3000
      };
      commit("SHOW_MESSAGE", snackbar);
      commit('SET_LOADING', false, { root: true });
      return true;
    } catch (error) {
      let errorMessage = "";
      switch (error.response.status) {
        case 500:
          errorMessage = "Problema sconosciuto del server, contatta un amministratore"
          break;
        case 404:
          errorMessage = "Non abbiamo trovato la tua utenza nei nostri database"
          break;
        case 401:
          errorMessage = "Non sei autorizzato a fare questa operazione"
          break;
        case 400:
          errorMessage = "Questa operazione non è consentita"
      }

      commit("SHOW_MESSAGE", {
        color: "error",
        message: errorMessage,
        show: true,
        timeout: 4000
      });
      console.warn("There was an errror during USER PATCH", error);
      commit('SET_LOADING', false, { root: true });
      return false;
    }
  },

  async patchOrganization({ commit, state, rootState }, payload) {
    try {
      const organizationID = rootState.localStorage.staffMember.organization.id;

      const res = await this.$axios.patch(`/organizations/${organizationID}`, payload);
      return true;
    } catch (error) {
      let errorMessage = "";
      switch (error.response.status) {
        case 500:
          errorMessage = "Problema sconosciuto del server, contatta un amministratore"
          break;
        case 404:
          errorMessage = "Non abbiamo trovato la tua utenza nei nostri database"
          break;
        case 401:
          errorMessage = "Non sei autorizzato a fare questa operazione"
          break;
        case 400:
          errorMessage = "Questa operazione non è consentita"
      }

      commit("SHOW_MESSAGE", {
        color: "error",
        message: errorMessage,
        show: true,
        timeout: 4000
      });
      console.warn("There was an errror during USER PATCH", error);
      commit('SET_LOADING', false, { root: true });
      return false;
    }
  },

  async patchPassword({ commit, state, rootState }, data) {
    commit('SET_LOADING', true, { root: true });
    try {
      await this.$axios.post("/users/change-password", data, {
        headers: {
          Authorization: "Bearer " + rootState.localStorage.bearer,
        },
      });
      commit('PASSWORD_UPDATED', this.$moment().format('LTS'));

      commit("SHOW_MESSAGE", {
        color: "success",
        message: "Abbiamo salvato la tua nuova password con successo",
        show: true,
        timeout: 3000
      });
    } catch (error) {
      let errorMessage = "";
      switch (error.response.status) {
        case 500:
          errorMessage = "Problema sconosciuto del server, contatta un amministratore"
          break;
        case 404:
          errorMessage = "Si è verificato un problema con la tua utenza, contatta un amministratore"
          break;
        case 401:
          errorMessage = "La password attuale non corrisponde, prova di nuovo"
      }

      commit("SHOW_MESSAGE", {
        color: "error",
        message: errorMessage,
        show: true,
        timeout: 4000
      });
      console.warn("There was an errror during PASSWORD PATCH", error);
    }
    commit('SET_LOADING', false, { root: true });
  },

  async uploadPromoterLogo({ commit, dispatch, state, rootState }, image) {
    commit('SET_LOADING', true, { root: true });

    try {
      const formData = new FormData();
      formData.append('image', image);

      const res = await this.$axios.post('/images', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + rootState.localStorage.bearer,
        }
      });
      commit('localStorage/USER_IMAGE_UPDATED', res.data[0]);

      try {
        dispatch('patchOrganization', { logo: res.data[0].id });
      } catch (err) {
        console.log(err)
      }
    } catch (error) {
      let errorMessage = "";
      switch (error?.response?.status) {
        case 500:
          errorMessage = "Problema sconosciuto del server, contatta un amministratore"
          break;
        case 404:
          errorMessage = "Non abbiamo trovato la tua utenza nei nostri database"
          break;
        case 401:
          errorMessage = "Non sei autorizzato a fare questa operazione"
      }
      commit("SHOW_MESSAGE", {
        color: "error",
        message: errorMessage,
        show: true,
        timeout: 4000,
      });
      console.warn("There was an errror during IMAGE UPLOAD", error);
    }
    commit('SET_LOADING', false, { root: true });
  },

};
