
import LanguageSelectModal from "./Modals/LanguageSelectModal";

export default {
  components: {
    LanguageSelectModal
  },
  data(){
    return {
      showLanguageSelectModal: false,
      items: [
        {
          icon: "mdi-license",
          text: "Account",
          to: "/account/profile",
          visibility: () => {
            return ["boardMember", "manager"].some(r => this.user.roles.includes(r));
          }
        },
      ],
    };
  },
  computed: {
    user () {
      return this.$store.state.staffMembers.me;
    },
    multipleContext () {
      return (this.$store.state.localStorage.me?.staffMembers?.length ?? 0) > 1;
    },
    loggedOrganization () {
      if (!this.user) return;
      return this.user.organization
    },
    logoPath() {
      return this.$image(this.loggedOrganization?.logo, "path");
    },
    userLogo() {
      return this.logoPath
        ? this.logoPath
        : require("assets/static/placeholders/promoter-placeholder.svg");
    },
    showAccountInfo() {
      return !!this.organization?.name;
    },
  },
  methods: {
    async doLogout() {
      let permission = confirm("Sei sicuro di voler effettuare il logout?");
      if (permission) {
        await this.$store.dispatch("user/logOut");
        this.$router.replace({ path: "/login" });
      }
    },
    async doChangeContext() {
      this.$router.replace({ path: "/login", query: { changeContext: 1 } });
    },
    doSelectLanguage() {
      this.showLanguageSelectModal = true;
    },
    onCloseLanguageSelectModal(){
      this.showLanguageSelectModal = false;
    },
  },
};
