
export default {
  methods: {
    showChangeLogWidget() {
      window.postMessage({
        target: "FeaturebaseWidget",
        data: { action: "toggleWidget" },
      });
    },
  },
  created() {
    window?.FeaturebaseWidget?.init({
      organization: "Dink",
      initialPage: "MainView",
      placement: "right",
      fullScreen: false,
    });
  },
};
