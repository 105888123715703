import byte from '@/utils/byte';
// import { translate } from '../../i18n';

const state = () => ({
});

const getters = {};

const mutations = {
};

const maxUploadSize = 5;

const maxFileSize = byte(process.env.MAX_FILE_SIZE || '5mb');
const actions = {
    async uploadFiles({commit, dispatch}, files) {
      try {
        commit('SET_LOADING', true, { root: true });
        const hasLargeFiles = files.some(f => f.size > maxFileSize);
        if (hasLargeFiles) {
            const toast = {
                show: true,
                color: "danger",
                duration: 3000,
                message: `Non è possibile caricare file superiori a ${maxUploadSize}MB`,
            };
            commit("user/SHOW_MESSAGE", toast, { root: true });
            return [];
        }

        const formData = new FormData();
        files.forEach((file) => {
            formData.append(file.type, file);
        });
        const { data } = await this.$axios.post("/files", formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
          },
        });
        return data;
      }
      catch (error) {
        console.error("There was an errror during IMAGE UPLOAD", error);
        return null;
      } finally {
        commit('SET_LOADING', false, { root: true });
      }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
