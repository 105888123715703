/** @typedef {ReturnType<state>} State */
/** @typedef {Record<string, (state: State, data) => void>} Mutation */
import { DateTime } from "luxon";

function getSubscriptionPlanStatus(plan) {
  const isSubscriptionEnabled = plan.enabled;
  const today = DateTime.now();
  const expiryDate = DateTime.fromISO(plan.expiresAt);

  // Compute remaining days
  const remainingDays = Math.floor(expiryDate.diff(today, "days").days);

  // Compute expired days
  const expiredDays = Math.floor(today.diff(expiryDate, "days").days);

  const fineObj = {
    value: 'active',
    days: remainingDays,
    label: `Attivo per ${remainingDays} giorni`,
    icon: "mdi-check",
    color: "success",
  };
  const expiringObj = {
    value: 'expiring',
    days: remainingDays,
    label: `Scade tra ${remainingDays} giorni`,
    icon: "mdi-alert",
    color: "warning",
  };
  const debtorObj = {
    value: 'expired',
    days: remainingDays,
    label: `Scaduto da ${expiredDays} giorni`,
    icon: "mdi-alert",
    color: "error",
  };

  if (isSubscriptionEnabled) {
    if (remainingDays > 30) return fineObj;
    if (remainingDays <= 30 && remainingDays > 0) return expiringObj;
    if (remainingDays <= 0) {
      return debtorObj;
    }
  } else {
    return null;
  }
}


export const state = () => ({
  dinkSubscriptions: {},
});

export const getters = {
  facilitiesManager: (state) => {
    const obj = state.dinkSubscriptions?.facilitiesManager;
    return {
      ...obj,
      statusInfo: getSubscriptionPlanStatus(state.dinkSubscriptions),
      status: getSubscriptionPlanStatus(state.dinkSubscriptions)?.value
    }
  },
}

/**
 * @type {Mutation} 
 */
export const mutations = {
  SET_DINK_SUBSCRIPTIONS(state, payload) {
    state.dinkSubscriptions = { ...payload }
  }
};

export const actions = {
  async getDinkSubscriptions({ state, commit }, data) {
    commit('SET_LOADING', true, { root: true });
    try {
      const res = await this.$axios.get(`organizations/${data.organizationID}/dinkSubscriptions`, { data });
      commit('SET_DINK_SUBSCRIPTIONS', res.data);
      return res.data;
    } catch (err) {
      console.error(err);
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: err.response.data.message,
      })
    }
  },
};
