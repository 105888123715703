import flagsmith from 'flagsmith';

export default async function({nuxtState, app}, inject) {
  await flagsmith.init({
    api: `${window.dink?.flagsmithBaseUrl || nuxtState.config.flagsmith.baseURL}/api/v1/`,
    environmentID: window.dink?.flagsmithEnvironment || nuxtState.config.flagsmith.environmentId,
    cacheFlags: false,
    preventFetch: true,
  });

  inject("flagIsEnabled", (flag) => {
    const value = flagsmith.hasFeature(flag);
    console.log({value})
    return value;
  });
  inject("flagValue", (flag) => flagsmith.getValue(flag));
};
