/** @typedef {ReturnType<state>} State */
/** @typedef {Record<string, (state: State, data) => void>} Mutation */

export const state = () => ({
});

export const getters = {
}

/**
 * @type {Mutation} 
 */
export const mutations = {
};

export const actions = {
    async createChampionship ({ commit }, params = {}) {
        try {
            const res = await this.$axios.post(`/championships`, params);
            return res.data || [];  
        } catch (error) {
            console.error(error);
        } finally {}
    },
    async getMyChampionships({ commit }, params = {}) {
        try {
            const res = await this.$axios.get(`/championships`, { params });
            return res.data || [];

        } catch (err) {
            console.error(err);
            return false
        } finally { }
    },
    async getChampionshipById({ commit }, params = {}) {
        try {
            const res = await this.$axios.get(`/championships/${params.data.id}`);
            return res.data;
        } catch (err) {
            console.error(err);
            return false
        } finally { }
    },
    async patchChampionship({ commit }, params = {}) {
        console.log(params)
        try {
            const res = await this.$axios.patch(`/championships/${params.id}`, params.payload);
            return res.data;
        } catch (err) {
            console.error(err);
            return false
        } finally { }
    },
    async deleteChampionship({ commit }, params) {
        try {
            const res = await this.$axios.delete(`/championships/${params.id}`);
            return true;
        } catch (error) {
            console.error(err);
            return false
        } finally {}
    }
};
