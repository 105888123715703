import Vue from "vue";
import VuetifyConfirm from "vuetify-confirm";

export default ({ app }, inject) => {
  Vue.use(VuetifyConfirm, {
    color: "error",
    property: "$vuetifyConfirm",
    vuetify: app.vuetify,
    width: 350,
  });

  function confirm(message, options) {
    return this.$vuetifyConfirm(message, {
      buttonFalseText: app.i18n.t("actions.cancel"),
      buttonTrueText: app.i18n.t("actions.yes"),
      ...options,
    });
  }

  inject("confirm", confirm);
};

// Reference page https://github.com/yariksav/vuetify-confirm
