export const state = () => ({
    memberships: [],
    membershipsExcludingLevel: [],
    membershipsExcludingGroup: [],
    membershipsExcludingSite: [],
    membership: {},
    membershipSubscriptions: [],
    membershipSubscriptionsPerTemplate: [],
    membershipSubscriptionsExcludingTemplate: [],
});

export const mutations = {
    SET_MEMBERSHIPS(state, payload) {
        state.memberships = [...payload];
    },
    SET_MEMBERSHIP(state, payload) {
        state.membership = payload;
    },
    PATCH_MEMBERSHIP(state, payload) {
        let mems = [...state.memberships]
        const memIndex = mems.findIndex((m) => m.id === payload.id);
        mems[memIndex] = Object.assign({}, payload);

        this.commit('memberships/SET_MEMBERSHIP', mems[memIndex]);
        this.commit('memberships/SET_MEMBERSHIPS', mems);
        console.log('MEMBERSHIPS AFTER PATCH => ', mems)
    },
    SET_MEMBERSHIPS_EXCLUDING_LEVEL(state, payload) {
        state.membershipsExcludingLevel = payload;
    },
    SET_MEMBERSHIPS_EXCLUDING_GROUP(state, payload) {
        state.membershipsExcludingGroup = payload;
    },
    SET_MEMBERSHIPS_EXCLUDING_SITE(state, payload) {
        state.membershipsExcludingSite = payload;
    },

    SET_MEMBERSHIP_SUBSCRIPTION_PER_TEMPLATE(state, payload) {
        state.membershipSubscriptionsPerTemplate = payload;
    },
    ADD_MEMBERSHIP_SUBSCRIPTION(state, payload) {
        state.membershipSubscriptions.unshift(payload);
    },
    PATCH_MEMBERSHIP_SUBSCRIPTION(state, payload) {
        let membershipSubscriptions = [...state.membershipSubscriptions]
        const msToEditIndex = membershipSubscriptions.findIndex(ms => ms.id === payload.id);
        membershipSubscriptions[msToEditIndex] = { ...payload }
    },
    DELETE_MEMBERSHIP_SUBSCRIPTION(state, payload) {

        state.membershipSubscriptions.forEach((s, i) => {
        })
        const index = state.membershipSubscriptions.findIndex(s => s.id === payload);

        state.membershipSubscriptions.splice(index, 1);

        state.membershipSubscriptions.forEach((s, i) => {
        })
    },

    SET_MEMBERSHIP_RECOVERY_TOKEN(state, amount) {
        state.membership = {
            ...state.membership,
            wallet: {
                ...(state.membership.wallet || {}),
                token: {
                    ...(state.membership?.wallet?.token || {}),
                    recovery: (state.membership?.wallet?.token?.recovery || 0) + amount
                }
            }
        };
    },
    SET_MEMBERSHIP_ASSET(state, data) {
        const { type, amount, recoveryVoucher } = data;
        state.membership = {
            ...state.membership,
            wallet: {
                ...state.membership.wallet,
                type: amount, // Use computed property name to set the dynamic property
                recoveryVoucher: data.recoveryVoucher, // Make sure you have recoveryVoucher data available in data object
            },
        };
        console.log(wallet);
    }
};

export const actions = {
    async getMemberships({ commit }, params = {}) {
        commit('SET_LOADING', true, { root: true });

        try {
          const res = await this.$axios.get(`/memberships`, { params });
          return res.data || [];
        } catch (err) {
            commit("user/SHOW_MESSAGE", {
                color: "error",
                message: "Ops, qualcosa è andato storto nel reperire la tua rosa atleti"
            }, { root: true });

            return null;
        } finally {
            commit('SET_LOADING', false, { root: true });
        }
    },
    async getMyMemberships({ commit }, filters) {
        commit('SET_LOADING', true, { root: true });

        try {
            let res;
            if (typeof filters === 'string')
                res = await this.$axios.get(`/memberships?${filters}&sort=athlete.lastName`);
            else
                res = await this.$axios.get(`/memberships`, { params: filters });

            commit('SET_MEMBERSHIPS', res.data);
            return res.data || [];

        } catch (err) {
            commit("user/SHOW_MESSAGE", {
                color: "error",
                message: "Ops, qualcosa è andato storto nel reperire la tua rosa atleti"
            }, { root: true });

            return null;
        } finally {
            commit('SET_LOADING', false, { root: true });
        }

    },

    async exportMemberships({ commit }, filters) {
        try {
            const res = await this.$axios.get(`/memberships/download`, { params: filters, responseType: 'blob' });
            return res.data;
        } catch (err) {
            commit("user/SHOW_MESSAGE", {
                color: "error",
                message: "Ops, qualcosa è andato storto nell'export dei dati"
            }, { root: true });
            return null;
        } finally {
        }

    },

    async getMembershipSubscriptions({ commit }, params = {}) {
        try {
            commit("SET_LOADING", true, { root: true });
            const res = await this.$axios.get(`/memberships/subscriptions`, { params });
            return res.data || [];
        } finally {
            commit("SET_LOADING", false, { root: true });
        }
    },

    async getMembershipsSubscriptionsPerTemplate({ commit, rootState }, params) {
        commit('SET_LOADING', true, { root: true });

        try {
            const res = await this.$axios.get(`/memberships/subscriptions`, { params });
            commit('SET_MEMBERSHIP_SUBSCRIPTION_PER_TEMPLATE', res.data);
            return res.data || [];

        } catch (err) {
            console.warn("Something went wrong while fetching this promoter's membership subscriptions", err),
                commit("user/SHOW_MESSAGE", {
                    color: "error",
                    message: "Ops, qualcosa è andato storto nel reperire gli abbonamenti fatti per questo pacchetto",
                    show: true,
                    timeout: 3000
                }, { root: true });
            return null;
        } finally {
            commit('SET_LOADING', false, { root: true });
        }

    },

    async getMembershipById({ commit, rootState }, params) {
        commit('SET_LOADING', true, { root: true });
        const membershipID = params.id;
        delete params.id;
        try {
            const res = await this.$axios.get(`/memberships/${membershipID}`, { params });
            commit('SET_MEMBERSHIP', res.data);
        } catch (err) {
            console.warn("Something went wrong while fetching this promoter's membership", err),
                commit("user/SHOW_MESSAGE", {
                    color: "error",
                    message: "Ops, qualcosa è andato storto nel reperire questo atleta",
                    show: true,
                    timeout: 3000
                }, { root: true });
        }

        commit('SET_LOADING', false, { root: true });
    },

    async patchMembership({ commit, rootState }, data) {
        commit('SET_LOADING', true, { root: true });

        try {
            const res = await this.$axios.patch(`/memberships/${data.membershipId}`, data, {
                headers: {
                    Authorization: "Bearer " + rootState.localStorage.bearer,
                }
            });
            commit('PATCH_MEMBERSHIP', res.data);
            commit("user/SHOW_MESSAGE", {
                color: "success",
                message: "Modifiche salvate con successo!",
                show: true,
                timeout: 3000
            }, { root: true });
            commit('SET_LOADING', false, { root: true });
            return true;
        } catch (err) {
            console.warn("Something went wrong while patching this membership", err),
                commit("user/SHOW_MESSAGE", {
                    color: "error",
                    message: "Ops, qualcosa è andato storto nel modificare questo atleta",
                    show: true,
                    timeout: 3000
                }, { root: true });
            commit('SET_LOADING', false, { root: true });
            return false;
        }

    },

    async patchMemberships({ commit }, data) {
        commit('SET_LOADING', true, { root: true });

        try {
            await this.$axios.patch(`/memberships`, data);

            commit("user/SHOW_MESSAGE", {
                color: "success",
                message: "Modifiche salvate con successo!"
            }, { root: true });
            return true;
        } catch (err) {
            commit("user/SHOW_MESSAGE", {
                color: "error",
                message: "Ops, qualcosa è andato storto nel modificare questi atleti",
            }, { root: true });
            return false;
        } finally {
            commit("SET_LOADING", false, { root: true });
        }

    },

    async getMyMembershipSubscriptions({ commit, rootState }, { membershipId, ...params }) {
        commit('SET_LOADING', true, { root: true });

        try {
            const res = await this.$axios.get(`/memberships/${membershipId}/subscriptions`, { params });
            return res.data || [];
        } catch (err) {
            console.warn("Something went wrong while fetching this promoter's membership subscriptions", err),
                commit("user/SHOW_MESSAGE", {
                    color: "error",
                    message: "Ops, qualcosa è andato storto nel reperire gli abbonamenti di quest'atleta",
                    show: true,
                    timeout: 3000
                }, { root: true });
        }

        commit('SET_LOADING', false, { root: true });
    },

    async createMembershipSubscription({ commit, rootState }, data) {
        const membershipId = data.membershipId;
        delete data.membershipId;
        commit('SET_LOADING', true, { root: true });

        try {
            const res = await this.$axios.post(`/memberships/${membershipId}/subscriptions`, data.membershipSubscription);
            commit('ADD_MEMBERSHIP_SUBSCRIPTION', res.data);
            commit("user/SHOW_MESSAGE", {
                color: "success",
                message: "Abbiamo aggiunto l'abbonamento con successo!",
                show: true,
                timeout: 3000
            }, { root: true });

            return {data: res.data};
        } catch (err) {
            console.warn("Something went wrong while creating this membership's subscription", err),
                commit("user/SHOW_MESSAGE", {
                    color: "error",
                    message: "Ops, qualcosa è andato storto nel creare questo abbonamento",
                    show: true,
                    timeout: 3000
                }, { root: true });
        }

        commit('SET_LOADING', false, { root: true });
    },

    async createMultipleMembershipSubscription({ commit, rootState }, data) {
        commit('SET_LOADING', true, { root: true });

        try {
            const res = await this.$axios.post(`/memberships/subscriptions`, data, {
                headers: {
                    Authorization: "Bearer " + rootState.localStorage.bearer,
                }
            });
            commit("user/SHOW_MESSAGE", {
                color: "success",
                message: "Abbiamo aggiunto gli abbonamenti con successo!",
                show: true,
                timeout: 3000
            }, { root: true });
            commit('SET_LOADING', false, { root: true });
            return true;
        } catch (err) {
            console.warn("Something went wrong while creating this membership's subscription", err),
                commit("user/SHOW_MESSAGE", {
                    color: "error",
                    message: "Ops, qualcosa è andato storto nel creare questi abbonamenti",
                    show: true,
                    timeout: 3000
                }, { root: true });
            commit('SET_LOADING', false, { root: true });
            return false;
        }

    },

    async patchMembershipSubscription({ commit }, { membershipId, membershipSubscription }) {
        commit('SET_LOADING', true, { root: true });

        try {
            const res = await this.$axios.patch(`/memberships/${membershipId}/subscriptions/${membershipSubscription.id}`, membershipSubscription);
            commit('PATCH_MEMBERSHIP_SUBSCRIPTION', res.data);

            const message = membershipSubscription.status === 'confirmed'
                ? 'La quota da pagare è stata settata a 0'
                : 'Abbiamo aggiornato la transazione con successo!';
            commit("user/SHOW_MESSAGE", {
                color: "success",
                message,
                show: true,
                timeout: 3000
            }, { root: true });

            return true;
        } catch (err) {
            console.warn("Something went wrong while patching this membership's subscription", err);
            return false;
        } finally {
            commit('SET_LOADING', false, { root: true });
        }
    },

    async patchMembershipSubscriptions({ commit }, { ids, setPaid, status }) {
        commit('SET_LOADING', true, { root: true });

        try {
            const res = await this.$axios.patch(`/memberships/subscriptions`, { ids, setPaid, status });

            const message = 'Abbiamo aggiornato le transazioni con successo!';

            commit("user/SHOW_MESSAGE", {
                color: "success",
                message,
                show: true,
                timeout: 3000
            }, { root: true });

            return true;
        } catch (err) {
            console.warn("Something went wrong while patching this membership's subscription", err);
            return false;
        } finally {
            commit('SET_LOADING', false, { root: true });
        }
    },

    async deleteMembershipSubscription({ commit, rootState }, data) {
        const membershipId = data.membershipID;
        const subscriptionId = data.subscriptionID;
        delete data.membershipId;
        commit('SET_LOADING', true, { root: true });

        try {
            const res = await this.$axios.delete(`/memberships/${membershipId}/subscriptions/${subscriptionId}`, {
                headers: {
                    Authorization: "Bearer " + rootState.localStorage.bearer,
                }
            });
            commit('DELETE_MEMBERSHIP_SUBSCRIPTION', subscriptionId);
            commit("user/SHOW_MESSAGE", {
                color: "success",
                message: "Abbiamo eliminato l'abbonamento con successo!",
                show: true,
                timeout: 3000
            }, { root: true });
        } catch (err) {
            console.warn("Something went wrong while deleting this membership's subscription", err),
                commit("user/SHOW_MESSAGE", {
                    color: "error",
                    message: "Ops, qualcosa è andato storto nel cancellare questo abbonamento",
                    show: true,
                    timeout: 3000
                }, { root: true });
        }

        commit('SET_LOADING', false, { root: true });
    },

    async deleteSingleTransaction({ commit }, payload) {
        try {
            const endpoint = `/memberships/${payload.membershipID}/subscriptions/${payload.transactionID}`;
            const res = await this.$axios.delete(endpoint);
            return true;
        } catch (error) {
            commit("user/SHOW_MESSAGE", {
                color: "error",
                message: "Qualcosa è andato storto nell'eliminare questa transazione",
                show: true,
                timeout: 3000
            }, { root: true });
        }
    },

    async deleteTransactions({ commit }, data) {
        try {
            const endpoint = "/memberships/subscriptions";
            const payload = {ids: data}
            const res = await this.$axios.delete(endpoint, {data: payload});
            return true;
        } catch (error) {
            commit("user/SHOW_MESSAGE", {
                color: "error",
                message: "Qualcosa è andato storto nell'eliminare le transazioni",
                show: true,
                timeout: 3000
            }, { root: true });
        }
    },

    async createDocumentClaim({ commit, rootState }, data) {
        commit('SET_LOADING', true, { root: true });
        try {
            const res = await this.$axios.post(`/reports`, data, {
                headers: {
                    Authorization: "Bearer " + rootState.localStorage.bearer,
                }
            });
            commit("user/SHOW_MESSAGE", {
                color: "success",
                message: "Abbiamo inviato la segnalazione con successo!",
                show: true,
                timeout: 3000
            }, { root: true });
        } catch (err) {
            console.warn("Something went wrong while creating this claim", err),
                commit("user/SHOW_MESSAGE", {
                    color: "error",
                    message: "Ops, qualcosa è andato storto nell'inviare la tua segnalazione",
                    show: true,
                    timeout: 3000
                }, { root: true });
        }

        commit('SET_LOADING', false, { root: true });
    },

    async updateMembershipWallet({ commit }, { membershipId, assetType, amount }) {
        try {
            const res = await this.$axios.post(`/memberships/${membershipId}/tokenMovements`, {
                type: assetType,
                amount
            });
        } catch (err) {
            commit("user/SHOW_MESSAGE", {
                color: "error",
                message: "Ops, qualcosa è andato storto nell'aggiornamento del wallet dell'atleta",
                show: true,
                timeout: 3000
            }, { root: true });
        }
    },

    async updateRecoveryTokens({ commit }, { membershipId, amount }) {
        commit('SET_LOADING', true, { root: true });
        try {
            const res = await this.$axios.post(`/memberships/${membershipId}/tokenMovements`, {
                amount,
                type: 'recovery'
            });

            commit("user/SHOW_MESSAGE", {
                color: "success",
                message: "Gettoni aggiornati con successo",
            }, { root: true });

            commit('SET_MEMBERSHIP_RECOVERY_TOKEN', res.data.amount);
        } catch (err) { }

        commit('SET_LOADING', false, { root: true });
    },

    async getTokenMovements({ commit }, params) {
        try {
            commit('SET_LOADING', true, { root: true });
            const res = await this.$axios.get(`/memberships/tokenMovements`, { params });
            return res.data || [];
        } catch (err) {
            console.error(err);
            return null;
        } finally {
            commit('SET_LOADING', false, { root: true });
        }
    }
};
