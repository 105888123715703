
export default {
  methods: {
    hideSnackbar(e) {
      this.$store.commit("user/HIDE_SNACKBAR");
    },
  },
  computed: {
    show: {
      get() {
        return this.$store.state.user.snackbar.show;
      },
      set(v) {
        this.$store.commit("user/HIDE_SNACKBAR", v);
      },
    },
  },
};
