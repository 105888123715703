export const state = () => ({
  registrationsPerEvent: [],
  registration: {},
});

export const mutations = {
  SET_REGISTRATIONS_PER_EVENT(state, payload) {
    state.registrationsPerEvent = payload;
  },
  UPDATE_REGISTRATION_PAYMENT(state, payload) {
    state.registration.paid = payload.paid;
    state.registrationsPerEvent.find(registrationPerEvent => payload.id === registrationPerEvent.id).paid = payload.paid;
  },
  SET_REGISTRATION(state, payload) {
    state.registration = payload;
  },
  CONFIRM_REGISTRATIONS(state, payload) {
    console.log(payload);
  },
  DELETE_REGISTRATION(state, registrationId) {
    let regs = state.registrationsPerEvent;
    let regPosition = regs.findIndex(r => r.id === registrationId);
    regs.splice(regPosition, 1);
  },
};

export const actions = {
  async fetchEventRegistrations({ commit, rootState }, eventId) {
    try {
      const res = await this.$axios.get('/registrations?event=' + eventId);
      return res.data;
    } catch (err) {
      console.warn("Something went wrong with registrations", err)
    }
  },
  async reorderRegistrations({ commit, rootState }, data) {
    try {
      const res = await this.$axios.post(`/registrations/${data.eventID}/reorderRankings`, data.payload);
      return res.data;
    } catch (err) {
      console.warn("Something went wrong with registrations", err);
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto nel riordinare le tue iscrizioni",
        show: true,
        timeout: 3000,
      }, { root: true });
      return null;
    }
  },
  async createRegistration({ commit }, payload) {
    try {
      const res = await this.$axios.post(`/registrations`, payload);
      return { data: res.data };

    } catch (err) {
      console.error(err);
      const errorName = err?.response?.data?.message ?? 'generic_create';
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: this.$i18n.t(`registrationErrors.${errorName}`),
        show: true,
        timeout: 3000,
      }, { root: true });
      return { error: errorName };
    } finally {
    }
  },
  async patchRegistrationTeams({ commit }, data) {
    try {
      const res = await this.$axios.patch('/registrations/' + data.registrationID, data);
      commit("user/SHOW_MESSAGE", {
        color: "success",
        message: "Abbiamo aggiornato la squadra con successo",
        show: true,
        timeout: 3000,
      }, { root: true });
      return { data: res.data }
    } catch (error) {
      console.error(error);
      const errorName = error?.response?.data?.message ?? 'generic_edit';
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: this.$i18n.t(`registrationErrors.${errorName}`),
        show: true,
        timeout: 3000
      }, { root: true });

      return { error: errorName };
    } finally {
    }

  },
  async patchRegistrationPayment({ commit, rootState }, data) {
    try {
      let body = data.body;
      const res = await this.$axios.patch('/registrations/' + data.registrationId, body);
      commit("user/SHOW_MESSAGE", {
        color: "success",
        message: "Abbiamo aggiornato i pagamenti con successo",
        show: true,
        timeout: 3000,
      }, { root: true });
      return true;
    } catch (error) {
      let errorMessage = "";
      switch (error.response.status) {
        case 500:
          errorMessage = "Problema sconosciuto del server, contatta un amministratore"
          break;
        case 404:
          errorMessage = "Non abbiamo trovato la tua utenza nei nostri database"
          break;
        case 401:
          errorMessage = "Non sei autorizzato a fare questa operazione"
          break;
        case 400:
          errorMessage = "Questa operazione non è consentita"
      }
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: errorMessage,
        show: true,
        timeout: 3000
      }, { root: true });
      console.warn("Something went wrong with registrations", error)
    }
  },
  async patchRegistration({ }, data) {
    const payload = data.payload;
    try {
      const res = await this.$axios.patch(`/registrations/${data.registrationID}`, payload);
      if (res) return res.data
    } catch (error) {
      console.warn('Something went wrong while editing this registration');
    }
  },
  async confirmRegistrations({ commit, rootState }, eventId) {
    try {
      const res = await this.$axios.post('/events/tournaments/' + eventId + '/confirmRegistrations', null);
      commit("user/SHOW_MESSAGE", {
        color: "success",
        message: "Abbiamo salvato le iscrizioni e generato la lista squadre con successo",
        show: true,
        timeout: 3000,
      }, { root: true });
      return true;
    } catch (error) {
      let errorMessage = "";
      if (!error.response) {
        errorMessage = 'Qualcosa è andato storto...'
      } else {

        switch (error.response.status) {
          case 500:
            errorMessage = "Problema sconosciuto del server, contatta un amministratore"
            break;
          case 404:
            errorMessage = "Non abbiamo trovato questo torneo nei nostri database"
            break;
          case 401:
            errorMessage = "Non sei autorizzato a fare questa operazione"
            break;
          case 400:
            errorMessage = "Questa operazione non è consentita"
        }
      }
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: errorMessage,
        show: true,
        timeout: 3000
      }, { root: true });
      console.warn("Something went wrong with registrations confirm", error)
    }
  },
  async reopenRegistrations({ commit, rootState }, eventId) {
    try {
      const res = await this.$axios.post(`/events/tournaments/${eventId}/cleanTeams`, null);
      commit("user/SHOW_MESSAGE", {
        color: "success",
        message: "Abbiamo riaperto le iscrizioni con successo",
        show: true,
        timeout: 3000,
      }, { root: true });
      return true;
    } catch (error) {
      let errorMessage = "";
      if (!error.response) {
        errorMessage = 'Qualcosa è andato storto...'
      } else {

        switch (error.response.status) {
          case 500:
            errorMessage = "Problema sconosciuto del server, contatta un amministratore"
            break;
          case 404:
            errorMessage = "Non abbiamo trovato questo torneo nei nostri database"
            break;
          case 401:
            errorMessage = "Non sei autorizzato a fare questa operazione"
            break;
          case 400:
            errorMessage = "Questa operazione non è consentita"
        }
      }
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: errorMessage,
        show: true,
        timeout: 3000
      }, { root: true });
      console.warn("Something went wrong with registrations reopening", error)
      return false;
    }
  },
  async deleteRegistration({ commit, rootState }, registrationId) {
    try {
      const res = await this.$axios.delete('/registrations/' + registrationId);
      commit("user/SHOW_MESSAGE", {
        color: "success",
        message: "Abbiamo concellato l'iscrizione con successo",
        show: true,
        timeout: 3000,
      }, { root: true });
      return true;
    } catch (error) {
      let errorMessage = "";
      if (!error.response) {
        errorMessage = 'Qualcosa è andato storto...'
      } else {

        switch (error.response.status) {
          case 500:
            errorMessage = "Problema sconosciuto del server, contatta un amministratore"
            break;
          case 404:
            errorMessage = "Non abbiamo trovato questa iscrizione nei nostri database"
            break;
          case 401:
            errorMessage = "Non sei autorizzato a fare questa operazione"
            break;
          case 400:
            errorMessage = "Questa operazione non è consentita"
        }
      }
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: errorMessage,
        show: true,
        timeout: 3000
      }, { root: true });
      console.warn("Something went wrong with registrations confirm", error)
    }
  }
};
