
import SnackBarFeedback from "../components/Feedback/SnackBarFeedback";
import FullPageMessage from "../components/FullPageMessage.vue";

export default {
  name: "blank",
  components: {
    SnackBarFeedback,
    FullPageMessage,
  },
  async created() {
    if (this.$store.state.localStorage.bearer) {
      this.$store.commit("localStorage/USER_LOGGED_IN");
      await this.$store.dispatch("user/fetchMe");
      if(!this.$route.query?.changeContext) {
        this.$router.push("/");
      }
    }
  },
};
