export const state = () => ({
    templates: [],
    template: {}
});

export const getters = {
}

export const mutations = {
    SET_TEMPLATES(state, payload) {
        state.templates = payload;
    },
    SET_TEMPLATE(state, payload) {
        state.template = { ...payload };
    },
    DELETE_TEMPLATE(state, id) {
        const index = state.templates.findIndex(t => t.id === id);
        state.templates.splice(index, 1);
    },
};

export const actions = {
    async createSubscriptionTemplate({ commit }, data) {
        try {
            const res = await this.$axios.post('/subscriptionTemplates', data);

            commit("user/SHOW_MESSAGE", {
                color: "success",
                message: "Abbiamo aggiunto il tuo pacchetto con successo",
                show: true,
                timeout: 3000
            }, { root: true });
            return res.data;
        } catch (err) {
            let msg = 'Ops, qualcosa è andato storto nel creare questo pacchetto';
            if (err.response.data.message.includes('already exists')) {
                msg = "Esiste già un pacchetto con questo nome"
            }
                commit("user/SHOW_MESSAGE", {
                    color: "error",
                    message: msg,
                    show: true,
                    timeout: 3000
                }, { root: true });
        }
    },
    async getSubscriptionTemplates({ commit, rootState }, params) {
        commit('SET_LOADING', true, { root: true });

        try {
            const res = await this.$axios.get(`/subscriptionTemplates`, {params});
            commit('SET_TEMPLATES', res.data);
            return res.data;
        } catch (err) {
            console.warn("Something went wrong while fetching this promoter's subscription templates", err),
                commit("user/SHOW_MESSAGE", {
                    color: "error",
                    message: "Ops, qualcosa è andato storto nel reperire i tuoi pacchetti",
                    show: true,
                    timeout: 3000
                }, { root: true });
        }

        commit('SET_LOADING', false, { root: true });
    },
    async getSubscriptionTemplateById({ commit, rootState }, templateID) {
        commit('SET_LOADING', true, { root: true });

        try {
            const res = await this.$axios.get(`/subscriptionTemplates/${templateID}`, {
                headers: {
                    Authorization: "Bearer " + rootState.localStorage.bearer,
                }
            });
            commit('SET_TEMPLATE', res.data);
        } catch (err) {
            console.warn("Something went wrong while fetching this promoter's subscription template", err),
                commit("user/SHOW_MESSAGE", {
                    color: "error",
                    message: "Ops, qualcosa è andato storto nel reperire questo pacchetto",
                    show: true,
                    timeout: 3000
                }, { root: true });
        }

        commit('SET_LOADING', false, { root: true });
    },
    async patchSubscriptionTemplate({commit, rootState}, data) {
        commit('SET_LOADING', true, { root: true });
        try {
            const res = await this.$axios.patch(`/subscriptionTemplates/${data.id}`, data, {
                headers: {
                    Authorization: "Bearer " + rootState.localStorage.bearer,
                }
            });
            commit('SET_TEMPLATE', res.data);
            commit("user/SHOW_MESSAGE", {
                color: "success",
                message: "Abbiamo modificato il pacchetto con successo",
                show: true,
                timeout: 3000
            }, { root: true });
            commit('SET_LOADING', false, { root: true });
            return true;
        } catch (err) {
            console.warn("Something went wrong while editing this promoter's template", err),
                commit("user/SHOW_MESSAGE", {
                    color: "error",
                    message: "Ops, qualcosa è andato storto nel modificare questo pacchetto",
                    show: true,
                    timeout: 3000
                }, { root: true });
            commit('SET_LOADING', false, { root: true });
            return false;
        }
    },
    async deleteSubscriptionTemplate({commit, rootState}, id) {
        commit('SET_LOADING', true, { root: true });
        try {
            const res = await this.$axios.delete(`/subscriptionTemplates/${id}`, {
                headers: {
                    Authorization: "Bearer " + rootState.localStorage.bearer,
                }
            });
            commit('DELETE_TEMPLATE', id );
            commit("user/SHOW_MESSAGE", {
                color: "success",
                message: "Abbiamo aggiornato i dati con successo",
                show: true,
                timeout: 3000
            }, { root: true });
            return res.data.id;
        } catch (err) {
            console.warn("Something went wrong while deleting this promoter's subscription template", err),
                commit("user/SHOW_MESSAGE", {
                    color: "error",
                    message: "Ops, qualcosa è andato storto nel cancellare questo pacchetto",
                    show: true,
                    timeout: 3000
                }, { root: true });
        }

        commit('SET_LOADING', false, { root: true });
        return false;
    },
    async reorderSubscriptions({commit}, data) {
        try {
            commit('SET_LOADING', true, { root: true });
            const res = await this.$axios.post(`/subscriptionTemplates/reorder`, data);

            commit("user/SHOW_MESSAGE", {
                color: "success",
                message: "Abbiamo aggiornato i dati con successo",
                show: true,
                timeout: 3000
            }, { root: true });

            return res.data;
        } finally {
            commit('SET_LOADING', false, { root: true });
        }
    }

};
