export const state = () => ({
});

export const getters = {}

export const mutations = {};

export const actions = {
    async fetchCommunications({ commit, rootState }, payload) {
        try {
            const res = await this.$axios.get('/events/tournaments/' + payload.eventID + '/communications?sort=-createdAt')
            return res.data;
        } catch (err) {
            console.warn("Something went wrong with communications", err)
        }
    },
    async postCommunication({ commit, rootState }, payload) {
        try {
            const res = await this.$axios.post('/events/tournaments/' + payload.eventID + '/communications', payload.communication)
            return true;
        } catch (err) {
            commit("user/SHOW_MESSAGE", {
                color: "error",
                message: "Qualcosa è andato storto",
                show: true,
                timeout: 3000
            }, { root: true });
            console.log("Something went wrong with the communication", err)
            return false;
        }
    },
    async patchCommunication({ commit, rootState }, payload) {
        try {
            const res = await this.$axios.patch('/events/tournaments/' + payload.eventID + '/communications/' + payload.communicationID, payload.body);
            return res.data;
        } catch (err) {
            commit("user/SHOW_MESSAGE", {
                color: "error",
                message: "Qualcosa è andato storto",
                show: true,
                timeout: 3000
            }, { root: true });
            console.log("Something went wrong with the communication patch", err)
            return false;
        }
    },
    async deleteCommunication({ commit, rootState }, payload) {
        try {
            await this.$axios.delete('/events/tournaments/' + payload.eventID + '/communications/' + payload.communicationID);

            return true;
        } catch (error) {
            let errorMessage = "";
            switch (error.response.status) {
                case 500:
                    errorMessage = "Problema sconosciuto del server, contatta un amministratore"
                    break;
                case 404:
                    errorMessage = "Non abbiamo trovato la risorsa da eliminare, contatta un amministratore"
                    break;
                case 400:
                    errorMessage = "Non puoi eliminare questa location perché è associata a un evento"
            }

            commit("user/SHOW_MESSAGE", {
                color: "error",
                message: errorMessage,
                show: true,
                timeout: 4000
            }, { root: true });
            console.warn("There was an errror during PASSWORD RECOVER", error);
            return false;
        }
    }
};
