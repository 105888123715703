export const state = () => ({
  events: [],
  event: {},
  myEvents: [],
  teams: [],
  tournamentPreview: {},
  tournamentStructure: {},
  eventMedia: []
});

export const getters = {
  events: state => {
    return state.events;
  },
  filteredEvents: state => {
    return [];
  },
  myEvents: state => {
    return state.myEvents;
  },
  eventHtmlDescription: state => {
    return state.event?.description?.replace(/\n/g, "<br />");
  },
  eventWelcomeKitHtmlDescription: state => {
    return state.event?.subscription?.welcomeKit.replace(/\n/g, "<br />");
  }
};

export const mutations = {
  SET_ALL_EVENTS(state, payload) {
    state.events = payload;
  },
  SET_MY_EVENTS(state, payload) {
    state.myEvents = payload;
  },
  SET_EVENT(state, payload) {
    state.event = payload;
  },
  SET_EVENT_TEAMS(state, payload) {
    state.teams = payload;
  },
  SET_TOURNAMENT_PREVIEW(state, payload) {
    state.tournamentPreview = payload;
  },
  SET_TOURNAMENT_STRUCTURE(state, payload) {
    state.tournamentStructure = payload
  },
  SET_EVENT_RESULTS(state, payload) {
    console.log('SET RESULTS');
  },
  SET_EVENT_MEDIA(state, payload) {
    state.eventMedia = payload
  },
  DELETE_MEDIA(state, id) {
    const mediaPosition = state.eventMedia.findIndex((m) => m.id === id);
    state.eventMedia.splice(mediaPosition, 1);
  },
  DELETE_TOURNAMENT_TEAMS(state) {
    state.teams = [];
  }
};

export const actions = {
  async fetchEvents({ commit }, { type, ...params }) {
    try {
      commit('SET_LOADING', true, { root: true });
      const res = await this.$axios.get(`/events/${type}`, { params });
      commit('SET_ALL_EVENTS', res.data);
      return res.data;
    } catch (err) {
      console.warn("Something went wrong with all events", err)
      return [];
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },
  async fetchMyEvents({ commit }, payload = {}) {
    try {
      const res = await this.$axios.get('/events', { params: payload });
      commit('SET_MY_EVENTS', res.data);
      return res.data;
    } catch (err) {
      console.warn("Something went wrong with my events")
      return [];
    }
  },
  async fetchEventById({ commit, rootState }, payload = {}) {
    const params = payload.params;
    const id = payload.id;

    try {
      const res = await this.$axios.get(`/events/${id}?type=${params.type}`);
      return res.data;
    } catch (err) {
      console.log("Something went wrong with the event")
    }
  },
  async fetchEvent({ commit, rootState }, payload = {}) {
    const params = payload.params;
    const id = payload.id;

    try {
      const res = await this.$axios.get(`/events/tournaments/${id}`, { params });
      return res.data;
    }
    catch (err) {
      console.log("Something went wrong with the event")
    }
  },
  async uploadEventCover({ commit, state, rootState }, image) {
    commit('SET_LOADING', true, { root: true });
    try {
      let formData = new FormData();
      formData.append('image', image);

      const res = await this.$axios.post("/images", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + rootState.localStorage.bearer,
        },
      });
      commit('SET_LOADING', false, { root: true });

      return res.data[0];
    } catch (error) {
      let errorMessage = "";
      switch (error.response.status) {
        case 500:
          errorMessage = "Problema sconosciuto del server, contatta un amministratore"
          break;
        case 404:
          errorMessage = "Non abbiamo trovato la tua utenza nei nostri database"
          break;
        case 401:
          errorMessage = "Non sei autorizzato a fare questa operazione"
      }

      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: errorMessage,
        show: true,
        timeout: 4000
      });
      console.warn("There was an errror during IMAGE UPLOAD", error);
    }
    commit('SET_LOADING', false, { root: true });
  },
  async createEvent({ commit }, data) {
    try {
      const res = await this.$axios.post('/events/tournaments', data);
      return res.data;
    } catch (err) {
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto",
        show: true,
        timeout: 3000
      }, { root: true });
      console.log("Something went wrong with the event", err)
      return false;
    }
  },
  async patchEvent({ commit }, event) {
    try {
      await this.$axios.patch('/events/tournaments/' + event.id, event);

      return true;
    } catch (err) {
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto",
        show: true,
        timeout: 3000
      }, { root: true });
      console.log("Something went wrong with the event", err)
      return false;
    }
  },
  async cleanTournament({ commit }, id) {
    try {
      await this.$axios.post(`/events/tournaments/${id}/cleanTournament`, null);

      return true;
    } catch (err) {
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Ops, qualcosa è andato storto",
        show: true,
        timeout: 3000
      }, { root: true });
      console.log("Something went wrong with the event's tournament cleaning", err)

      return false;
    }
  },
  async cleanTeams({ commit }, id) {
    try {
      await this.$axios.post(`/events/tournaments/${id}/cleanTeams`, null);
      commit("user/SHOW_MESSAGE", {
        color: "success",
        message: "Abbiamo cancellato la entry list del torneo con successo",
        show: true,
        timeout: 3000,
      }, { root: true });
      return true;
    } catch (err) {
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Ops, qualcosa è andato storto",
        show: true,
        timeout: 3000
      }, { root: true });
      console.log("Something went wrong with the event's teams cleaning", err)
      return false;
    }
  },
  async cancelEvent({ commit }, id) { // needs refactoring
    const body = {
      "cancelMessage": "cancelEventMessageTBI"
    }
    try {
      await this.$axios.post('/events/tournaments/' + id + '/cancel', body);

      commit("user/SHOW_MESSAGE", {
        color: "success",
        message: "L'evento è stato annullato con successo",
        show: true,
        timeout: 3000,
      }, { root: true });

      return true;
    } catch (err) {
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto",
        show: true,
        timeout: 3000
      }, { root: true });
      console.log("Something went wrong with the event", err)
      return false;
    }
  },
  async deleteEvent({ commit }, id) {
    try {
      await this.$axios.delete('/events/tournaments/' + id);

      commit("user/SHOW_MESSAGE", {
        color: "success",
        message: "Evento cancellato con successo",
        show: true,
        timeout: 3000,
      }, { root: true });
      return true;
    } catch (err) {
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto",
        show: true,
        timeout: 3000
      }, { root: true });
      console.log("Something went wrong with the event", err)
      return false;
    }
  },
  async fetchEventTeams({ commit }, eventId) {
    try {
      const res = await this.$axios.get('/events/tournaments/' + eventId + '/teams?sort=ranking');
      return res.data;
    } catch (err) {
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto",
        show: true,
        timeout: 3000
      }, { root: true });
      console.warn("Something went wrong with this event's teams", err)
      return []
    }
  },
  async fetchEventMatches({ commit, rootState }, eventId) {
    commit('SET_LOADING', true, { root: true });

    try {
      const res = await this.$axios.get('/events/tournaments/' + eventId + '/matches', {
        headers: {
          Authorization: "Bearer " + rootState.localStorage.bearer,
        },
      });
      commit('SET_EVENT_MATCHES', res.data);
      commit('SET_LOADING', false, { root: true });
      return true;
    } catch (err) {
      console.warn("Something went wrong with this event's matches", err)
      commit('SET_LOADING', false, { root: true });
      return false;
    }

  },
  async getTournamentPreview({ commit }, payload) {
    console.log(payload)
    try {
      const res = await this.$axios.post('/events/tournaments/' + payload.eventID + '/tournamentPreview', payload.settings,);
      return res.data;
    } catch (err) {
      console.warn("Something went wrong with this event's preview", err)
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto nella preview del torneo",
        show: true,
        timeout: 3000
      }, { root: true });
      return false;
    }
  },
  async getOpenTournamentPreview({ commit }, data) {
    try {
      const res = await this.$axios.post('/public/tournamentPreview', data);
      return res.data;
    } catch (err) {
      console.warn("Something went wrong with this event's preview", err)
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto nella preview del torneo",
        show: true,
        timeout: 3000
      }, { root: true });
      return false;
    }
  },
  async generateTournament({ commit }, payload) {
    try {
      const res = await this.$axios.post(`/events/tournaments/${payload.eventID}/tournamentGenerate`, payload.body);
      return res.data;
    } catch (err) {
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto nella generazione del torneo",
      }, { root: true });
      return false;
    }
  },
  async getTournamentStructure({ commit }, eventID) {
    try {
      const res = await this.$axios.get('/events/tournaments/' + eventID + '/tournament');
      return res.data;
    } catch (err) {
      console.warn("Something went wrong with this event's structure generation", err)
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto nel reperire la struttura del torneo",
        show: true,
        timeout: 3000
      }, { root: true });
      return false;
    }
  },
  async reorderTeams({ commit }, payload) {
    try {
      const res = await this.$axios.post('/events/tournaments/' + payload.eventID + '/reorderTeams', { rankings: payload.rankings });
      return res.data;
    } catch (err) {
      console.warn("Something went wrong with this event's teams reorder", err)
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto nel riordinamento delle squadre",
        show: true,
        timeout: 3000
      }, { root: true });
      return [];
    }
  },
  async createEventResults({ commit }, payload) {
    let ep = `/events/tournaments/${payload.eventID}/results`
    try {
      const res = await this.$axios.post(ep, payload.body);
      // const retObj = {}
      // retObj.resultId = res.data.id;
      // retObj.sets = res.data.sets;
      // retObj.outcome = res.data.outcome;
      // return retObj;
      console.log(res.data);
      return res.data;

    } catch (err) {
      console.warn("Something went wrong with this event's results", err)
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto nell'invio dei risultati",
        show: true,
        timeout: 3000
      }, { root: true });
    }
  },
  async patchEventResults({ commit }, payload) {
    try {
      const res = await this.$axios.patch(`/events/tournaments/${payload.eventID}/results/${payload.body.resultId}`, payload.body);
      return res.data;
    } catch (err) {
      console.warn("Something went wrong with this event's results", err)
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto nella modifica dei risultati",
        show: true,
        timeout: 3000
      }, { root: true });
      return false;
    }
  },
  async patchMatchNotes({ commit }, payload) {
    try {
      await this.$axios.patch(`/events/tournaments/${payload.eventID}/matches/${payload.matchId}`, payload.body);

      return true;
    } catch (err) {
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto",
        show: true,
        timeout: 3000
      }, { root: true });
      console.log("Something went wrong with the match notes", err)
      return false;
    }
  },
  async patchMatchTime({ commit }, { eventID, matchID, ...data }) {
    try {
      await this.$axios.patch(`/events/tournaments/${eventID}/matches/${matchID}`, data)

      return true;
    } catch (err) {
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto",
        show: true,
        timeout: 3000
      }, { root: true });
      console.log("Something went wrong with the match time", err)
      return false;
    }
  },
  async batchMatches({ commit }, { eventID, ...data }) {
    try {
      await this.$axios.patch(`/events/tournaments/${eventID}/matches`, data)

      return true;
    } catch (err) {
      console.error("Something went wrong with the match time", err)
      return false;
    }
  },
  async patchMatchField({ commit }, { eventID, matchID, ...data }) {
    try {
      await this.$axios.patch(`/events/tournaments/${eventID}/matches/${matchID}`, data)
      return true;
    } catch (err) {
      console.error("Something went wrong with the match field", err)
      return false;
    }
  },
  async patchMatchStatus({ commit }, payload) {
    console.log(payload)
    try {
      await this.$axios.patch('/events/tournaments/' + payload.eventID + '/matches/' + payload.matchID, payload.body);
      return true;
    } catch (err) {
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto",
        show: true,
        timeout: 3000
      }, { root: true });
      console.log("Something went wrong with the match status patch", err)
      return false;
    }
  },
  async patchEventSponsors({ commit }, event) {
    console.log(event.sponsors)
    try {
      commit('SET_LOADING', true, { root: true });
      const res = await this.$axios.patch(`/events/tournaments/${event.id}`, {
        sponsors: event?.sponsors?.map(s => s.id) || []
      });

      return res.data;
    } catch (err) {
      console.log(err)
      return false;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },

  async patchMatchLiveStreaming({ commit }, payload) {
    try {
      await this.$axios.patch(`/events/tournaments/${payload.eventID}/matches/${payload.matchId}`, payload.body);
      return true;
    } catch (err) {
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto",
        show: true,
        timeout: 3000
      }, { root: true });
      console.log("Something went wrong with the match live streaming", err)
      return false;
    }
  },
  async closeTournamentRound({ commit }, payload) {
    try {
      await this.$axios.post(`/events/tournaments/${payload.eventId}/closeRound`, { roundIndex: payload.roundNumber })
      return true;
    }
    catch (err) {
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto",
        show: true,
        timeout: 3000
      }, { root: true });
      console.log("Something went wrong while closing a tournament round", err)
      return false;
    }
  },
  async reopenTournamentRound({ commit }, payload) {
    try {
      await this.$axios.post(`/events/tournaments/${payload.eventId}/reopenRound`, { roundIndex: payload.roundIndex })
      return true;
    }
    catch (err) {
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Ops, qualcosa è andato storto",
        show: true,
        timeout: 3000
      }, { root: true });
      commit('SET_LOADING', false, { root: true });
      console.log("Something went wrong while reopening a tournament round", err)
      return false;
    }
  },
  async fetchEventMedia({ commit }, eventID) {
    try {
      const res = await this.$axios.get(`/events/tournaments/${eventID}/media`);
      return res.data;
    } catch (err) {
      console.warn("Something went wrong with this event's media", err)
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto nel reperire la gallery del torneo",
        show: true,
        timeout: 3000
      }, { root: true });
      return false;
    }
  },
  async addEventMedia({ commit }, payload) {
    try {
      // Created the form data
      let formData = new FormData();
      payload.images.forEach(image => {
        formData.append('image', image);
      })

      // Sends the resources to the API
      const resourcesApi = await this.$axios.post("/images", formData);

      try {
        // Creates an array of resources ID based on the resource response
        let ids = [];
        resourcesApi.data.forEach(el => {
          ids.push(el.id);
        })
        const body = { media: ids }

        // Sends the array of the IDs to create the gallery
        const assignResources = await this.$axios.post(`/events/tournaments/${payload.eventID}/media`, body);
        return true;
      }
      catch (err) {
        commit("user/SHOW_MESSAGE", {
          color: "error",
          message: "Qualcosa è andato storto nel salvataggio della gallery",
          show: true,
          timeout: 3000,
        }, { root: true });
        return false;
      }
    } catch (error) {
      console.log(error)
      let errorMessage = "";
      switch (error?.response?.status) {
        case 500:
          errorMessage = "Problema sconosciuto del server, contatta un amministratore"
          break;
        case 404:
          errorMessage = "Non abbiamo trovato la tua utenza nei nostri database"
          break;
        case 401:
          errorMessage = "Non sei autorizzato a fare questa operazione"
        default:
          errorMessage = "Ops, qualcosa è andato storto"
      }

      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: errorMessage,
        show: true,
        timeout: 4000
      });
      console.warn("There was an error during IMAGE UPLOAD", error);
      return false;
    }
  },
  async deleteEventMedia({ commit }, payload) {
    try {
      await this.$axios.delete(`/events/tournaments/${payload.eventID}/media/${payload.mediaID}`);
      return true;
    } catch (err) {
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Ops, qualcosa è andato storto",
        show: true,
        timeout: 3000
      }, { root: true });
      console.log("Something went wrong with the media file deletion", err)
      return false;
    }
  },


  // Open Days
  async getOpenEvents({ commit }, params = {}) {
    commit('SET_LOADING', true, { root: true });
    try {
      const result = await this.$axios.get('/events/openDays', { params });
      return result.data || [];

    } catch (err) {
      console.error("Something went wrong with the event", err)
      return null;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },
  async getOpenEvent({ commit }, id) {
    commit('SET_LOADING', true, { root: true });
    try {
      const result = await this.$axios.get(`/events/openDays/${id}`);
      return result.data;
    } catch (err) {
      console.error("Something went wrong while fetching the event", err)
      return null;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },
  async createOpenEvent({ commit }, data) {
    commit('SET_LOADING', true, { root: true });
    try {
      const result = await this.$axios.post('/events/openDays', data);
      return result.data;

    } catch (err) {
      console.error("Something went wrong with the event", err)
      return null;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },
  async getOpenEventParticipants({ commit }, { id, search }) {
    commit('SET_LOADING', true, { root: true });
    try {
      const result = await this.$axios.get(`/events/openDays/${id}/participants`, { params: { search } });
      return result.data || [];
    } catch (err) {
      console.error("Something went wrong with the event", err)
      return null;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },
  async updateOpenEvent({ commit }, { id, ...data }) {
    commit('SET_LOADING', true, { root: true });
    try {
      const result = await this.$axios.patch(`/events/openDays/${id}`, data);
      return result.data;

    } catch (err) {
      console.error("Something went wrong with the event", err)
      return null;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },
  async deleteOpenEvent({ commit }, id) {
    commit('SET_LOADING', true, { root: true });
    try {
      await this.$axios.delete(`/events/openDays/${id}`);
      return true;

    } catch (err) {
      console.error(err)
      return false;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },
  // End open Events
  async changeEventTournamentVisibility({ commit }, { eventID, visibility, notifyAthletes }) {
    try {
      commit('SET_LOADING', true, { root: true });
      const res = await this.$axios.post(`/events/tournaments/${eventID}/changeVisibility`, { visibility, notifyAthletes });
      return res.data;
    } catch (err) {
      console.log("Something went wrong with the event");
      return null;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },
};
