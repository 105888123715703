export const actions = {
  async create({ commit, state }, data) {
    try {
      const res = await this.$axios.post("/sponsors", data);
      return res.data;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
    }
  },

  async update({ commit, state }, {id, ...data}) {
    commit('SET_LOADING', true, { root: true });
    try {
      const res = await this.$axios.patch(`/sponsors/${id}`, {
        ...data,
        url: data.url || undefined,
        textURL: data.textURL || undefined,
      });
      return res.data;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
    }
  },

  async delete({ commit, state }, sponsor) {
    try {
      await this.$axios.delete(`/sponsors/${sponsor.id}`);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
    }
  },
};
