export const state = () => ({
    athletes: [],
});

/**
 * @type {Mutation} 
 */
export const mutations = {
    SET_ATHLETES(state, payload) {
        state.athletes = [...payload];
    },
};

export const actions = {
    async getAthletes({ commit }, params = {}) {
        try {
            const res = await this.$axios.get(`/athletes`, { params });
            commit('SET_ATHLETES', res.data);

            return res.data || [];
        } catch (err) {
            commit("user/SHOW_MESSAGE", {
                color: "error",
                message: "Ops, qualcosa è andato storto nel recuperare la lista degli atleti"
            }, { root: true });
        } finally {
            commit('SET_LOADING', false, { root: true });
        }
    },
};
