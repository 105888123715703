
export default {
  name: "MenuNode",
  props: {
    children: {
      type: Array,
      default: () => [],
    },
    nodeLevel: {
      type: Number,
      default: 0,
    },
  },
};
