import { version } from '../../package.json';
import axios from 'axios';

export default async function({ store, $config, i18n }, inject) {
  const handleError = (error) => {
    console.error(error);
    store.commit("user/SHOW_MESSAGE", {
      color: "error",
      message: "Ops, qualcosa è andato storto",
    }, { root: true });

    return Promise.reject(error);
  }

  // An interceptor to set authorization header for all requests
  axios.interceptors.request.use((config) => {
    if (!config.headers.Authorization) {
      if (config.url?.startsWith('/users/')) {
        config.headers.Authorization = `Bearer ${store.state?.localStorage?.userBearer}`;
      } else if (store.state?.localStorage?.bearer) {
        config.headers.Authorization = `Bearer ${store.state?.localStorage?.bearer}`;
      }
    }

    // Set app version for each request to the server
    config.headers['x-app-version'] = version;
    config.headers['x-application'] = "webapp";
    config.headers['accept-language'] = i18n.locale;

    // This is just a helper to remove [...] from array stringified strings
    // since the server doesn't support them
    config.paramsSerializer = {
      indexes: null
    }
    
    return config;
  }, handleError);
  
  axios.defaults.baseURL = window?.dink?.apiBaseUrl || process.env.API_BASE_URL || $config.apiBaseUrl || 'https://dink.social/api';
  inject('axios', axios);
};
