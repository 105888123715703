
import InformationTooltip from "../Content/InformationTooltip.vue";
import BaseModal from "../Modals/BaseModal.vue";
import TimeRangeAvailability from "../Widgets/TimeRangeAvailability.vue";
export default {
  components: { InformationTooltip, TimeRangeAvailability, BaseModal },
  props: {
    timeFrames: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showSelectionModal: false,
      customPopupDates: null,
    };
  },
  computed: {
    selectedItemsText() {
      if (!this.customPopupDates) return;
      const startAt = this.customPopupDates?.length
        ? this.customPopupDates[0]
        : null;
      const endAt =
        this.customPopupDates?.length > 0 ? this.customPopupDates[1] : null;
      if (!startAt && !endAt) return;
      const beautifiedStartDate = this.$DateTime
        .fromISO(startAt)
        .toFormat("d MMM");
      const beautifiedEndDate = this.$DateTime.fromISO(endAt).toFormat("d MMM");
      return `Dal ${beautifiedStartDate} al ${beautifiedEndDate}`;
    },
  },
  methods: {
    setTimeFrame({startAt, endAt, id, name, skipModal = false}) {
      const storeTimeFrames = this.$store.state.configs.configs.timeFrames;
      if (id === 'custom' && !skipModal) {
        this.showSelectionModal = true;
        return;
      }
      
      const timeFrameName = storeTimeFrames.find(f => f.id === id)?.name;
      this.$store.commit("configs/SET_CURRENT_TIME_FRAME", {
        startAt, endAt,
        name: name || timeFrameName,
      });
      sessionStorage.setItem("timeRangeAvailability", JSON.stringify({startAt, endAt, id, name: name || timeFrameName }));
    },
    selectionModalClosed() {
      this.showSelectionModal = false;
      if (this.customPopupDates?.length > 1) {
        const [startAtDate, endAtDate] = this.customPopupDates;
        const startAt = this.$DateTime.fromISO(startAtDate).toUTC().toISO();
        const endAt = this.$DateTime.fromISO(endAtDate).toUTC().toISO();

        const timeFrameObj = {
          id: "custom",
          startAt,
          endAt,
          name: null,
        };
        sessionStorage.setItem("timeRangeAvailability", JSON.stringify(timeFrameObj));
        this.$store.commit("configs/SET_CURRENT_TIME_FRAME", timeFrameObj);
      }
    },
  },
};
