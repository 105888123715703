export const state = () => ({
  promotions: []
});


export const mutations = {
  SET_PROMOTIONS(state, promotions) {
    state.promotions = promotions;
  }
};

export const actions = {
  async getPromotion({ commit }, id) {
    commit('SET_LOADING', true, { root: true });

    try {
      const res = await this.$axios.get(`/promotions/${id}`)

      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },
  async getPromotions({ commit }) {
    commit('SET_LOADING', true, { root: true });

    try {
      const res = await this.$axios.get(`/promotions`)
      commit('SET_PROMOTIONS', res.data || []);

      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },
  async createPromotion({ commit, rootState }, data) {
    commit('SET_LOADING', true, { root: true });

    try {
      const res = await this.$axios.post(`/promotions`, data)
      const {promotions} = rootState.promotions;
      commit('SET_PROMOTIONS', [res.data, ...promotions]);

      commit("user/SHOW_MESSAGE", {
        color: "success",
        message: `Promozione creata con successo`
      }, { root: true });

      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },
  async editPromotion({ commit, rootState }, {data, id}) {
    commit('SET_LOADING', true, { root: true });

    try {
      const res = await this.$axios.patch(`/promotions/${id}`, data)
      const {promotions} = rootState.promotions.promotions.filter(promotion => promotion.id !== id);
      commit('SET_PROMOTIONS', [res.data, ...promotions]);

      commit("user/SHOW_MESSAGE", {
        color: "success",
        message: `Promozione aggiornata con successo`
      }, { root: true });

      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },
  async deletePromotion({ commit, rootState }, promotionId) {
    try {
      commit('SET_LOADING', true, { root: true });
      await this.$axios.delete(`/promotions/${promotionId}`)
      const {promotions} = rootState.promotions;
      commit('SET_PROMOTIONS', promotions.filter(promotion => promotion.id !== promotionId));

      commit("user/SHOW_MESSAGE", {
        color: "success",
        message: `Promozione cancellata con successo`
      }, { root: true });

      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },
};
