export const state = () => ({
    sports: null,
});

export const getters = {}

export const mutations = {
    SET_SPORTS(state, payload) {
        state.sports = payload;
    },
};

export const actions = {
    async fetchSports({ commit, state }) {
        if (state.sports) {
          return state.sports;
        }

        try {
            const res = await this.$axios.get('/sports');
            const sports = res.data.sports || [];
            commit('SET_SPORTS', sports);
            return sports;
        } catch (err) {
            commit("user/SHOW_MESSAGE", {
                color: "error",
                message: "Qualcosa è andato storto nel recuperare gli sport",
                show: true,
                timeout: 3000,
            }, { root: true });
            console.warn("Something went wrong with sports", err)
        }
    },
};
