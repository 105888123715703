export const state = () => ({
  eventStats: [],
  membershipsStats: [],
});

export const getters = {}

export const mutations = {
  SET_EVENT_STATS(state, payload) {
    state.eventStats = [...payload];
  },
  SET_MEMBERSHIPS_STATS(state, payload) {
    state.membershipsStats = payload;
  },
};

export const actions = {
  async fetchEventStats({ commit, rootState }, data) {
    commit('SET_LOADING', true, { root: true });

    try {
      const res = await this.$axios.get('/promoters/stats/events?aggregateBy=' + data.queryString, {
        headers: {
          Authorization: "Bearer " + rootState.localStorage.bearer,
        }
      });
      commit('SET_EVENT_STATS', res.data);
    } catch (err) {
      console.warn("Something went wrong with the events stats", err)
    }

    commit('SET_LOADING', false, { root: true });
  },
  async fetchMembershipsStats({ commit, rootState }, data) {
    commit('SET_LOADING', true, { root: true });

    try {
      const res = await this.$axios.get('/promoters/stats/memberships?aggregateBy=' + data.queryString, {
        headers: {
          Authorization: "Bearer " + rootState.localStorage.bearer,
        }
      });
      commit('SET_MEMBERSHIPS_STATS', res.data);
    } catch (err) {
      console.warn("Something went wrong with the memberships stats", err)
    }

    commit('SET_LOADING', false, { root: true });
  },
};
