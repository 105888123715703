export const state = () => ({
});

export const mutations = {
};

export const actions = {
  async create({ commit }, { payment, debit }) {
    try {
      const { data } = await this.$axios.post(`/debits/${debit.id}/payments`, payment)
      return { data };
    } catch (error) {
      console.error(error);
      return { error };
    }
  },
  async patch({ commit }, { payment, debit }) {
    try {
      const { data } = await this.$axios.patch(`/debits/${debit.id}/payments/${payment.id}`, payment)
      return { data };
    } catch (error) {
      console.error(error);
      return { error };
    }
  },
  async delete({ commit }, { paymentId, debitId }) {
    try {
      const { data } = await this.$axios.delete(`/debits/${debitId}/payments/${paymentId}`)
      return { data };
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
};

