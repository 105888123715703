export const state = () => ({});


export const mutations = {};

export const actions = {
  /**
   * Upload the given image and return it's data from the server
   * @param {File} image - The image to be uploaded
   */
  async uploadImage({ commit }, image) {
      commit('SET_LOADING', true, { root: true });
      try {
          let formData = new FormData();
          formData.append('image', image);

          const res = await this.$axios.post("/images", formData, {
              headers: {
                  'Content-Type': 'multipart/form-data',
              },
          });

          return res.data[0];
      } catch (error) {
          console.error("There was an errror during IMAGE UPLOAD", error);
          return null;
      } finally {
        commit('SET_LOADING', false, { root: true });
      }
  },
};
