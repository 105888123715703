
export default {
  props: {
    message: {
      type: String,
    },
    imgSrc: {
      type: String,
    },
    hideLogo: {
      type: Boolean,
      default: false
    },
  },
};
